import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Albums, Pagination } from "../../components"

const AlbumsPage = ({ data, pageContext }) => {
  const {
    allAirtable: { nodes: albums },
  } = data

  // console.log("poems.js data", data)
  // console.log("poems.js pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <Albums title="1001 Lemez" albums={albums} page />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-light-3);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query AlbumsQuery($skip: Int, $limit: Int) {
    allAirtable(
      filter: { table: { eq: "Albums" } }
      limit: $limit
      skip: $skip
      sort: { order: ASC, fields: [data___albums_date, data___albums_title] }
    ) {
      nodes {
        id
        recordId
        data {
          albums_title
          albums_excerpt
          albums_performer
          albums_date
          albums_row
          albums_text {
            childMarkdownRemark {
              html
            }
          }
          albums_image {
            localFiles {
              childImageSharp {
                fluid {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AlbumsPage
